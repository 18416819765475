$(function() {

	// slider config

	$('.stage-slider').slick({
		autoplay:         true,
		arrows:           false,
		dots:             true,
		pauseOnDotsHover: true,
		slidesToShow:     2,
		slidesToScroll:   2,
		responsive:       [{
			breakpoint: 1500,
			settings: {
				adaptiveHeight: true,
				slidesToShow:   1,
				slidesToScroll: 1
			}
		}]
	});

	$(document.body)
		.on('change', '#navSidebarToggle', function (e) {
			$('html').toggleClass('navOpened', $(this).prop('checked'));
			$('body').toggleClass("scrollbar-width"); // scrollbar-width
		});

});